<template>
  <div class="agentAssociation fadeInRight">
    <el-tabs v-model="activeName" @tab-click="handleClick">
     
      <el-tab-pane label="网卡关联" name="first">
        <net-card></net-card>
      </el-tab-pane>
        <el-tab-pane label="号码库关联" name="second"  >
        <num-lib></num-lib>
      </el-tab-pane>
       <el-tab-pane label="代理商关联" name="three">
        <div class="title-box clearfix">
          <el-form
            :inline="true"
            :model="formInline"
            class="right themed-form search-form"
            style="height: auto; line-height: 40px"
          >
          <el-form-item
                label="代理商:"
              >
                <el-select
                  placeholder="代理商"
                  v-model="formInline.agentId"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in operationAgent"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
           <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-plus"
                type="primary"
                class="themed-button"
                @click="add"
                >新增</el-button
              >
            </el-form-item>
            
          </el-form>
        </div>
        <div class="content-box">
          <el-table
            ref="table"
            border
            stripe
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :max-height="maxTableHeight"
          >
            <el-table-column prop="agentName" label="代理商" min-width="180">
            </el-table-column>
            <!-- <el-table-column prop="type" label="配置类型" min-width="180">
        </el-table-column> -->
            <el-table-column prop="typeStr" label="配置类型" min-width="120">
            </el-table-column>
            <!-- <el-table-column prop="referenceId" label="关联配置id" min-width="120">
        </el-table-column> -->
            <el-table-column
              prop="referenceName"
              label="关联配置"
              min-width="120"
            >
            </el-table-column>

            <el-table-column label="操作" min-width="150">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  class="themed-button"
                  @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  class="themed-button"
                  @click="delect(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="page"
            :page-sizes="[5, 10, 15, 50, 100, 1000]"
            :page-size="pageSize"
            style="text-align: left; margin-top: 20px"
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="onPageChange"
          ></el-pagination>

          <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
            <el-form :model="addForm" :rules="rules" ref="addForm">
              <el-form-item
                label="代理商:"
                prop="agentId"
                :label-width="formLabelWidth"
              >
                <el-select
                  placeholder="代理商"
                  v-model="addForm.agentId"
                  filterable
                  :disabled="isEDIT"
                >
                  <el-option
                    v-for="item in operationAgent"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="配置类型:"
                prop="type"
                :label-width="formLabelWidth"
              >
                <el-select
                  placeholder="配置类型"
                  v-model="addForm.type"
                  filterable
                  @change="typeChange"
                  :disabled="isEDIT"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                label="关联配置:"
                prop="referenceId"
                :label-width="formLabelWidth"
              >
                <el-select
                  placeholder="关联配置"
                  v-model="addForm.referenceId"
                  filterable
                >
                  <el-option
                    v-for="item in referenceList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <!-- <el-input v-model="addForm.referenceId" autocomplete="off"></el-input> -->
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureAdd('addForm')"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </el-tab-pane>
      <el-tab-pane label="默认" name="four" v-if="currentRole == 0" >
          <default-ass></default-ass>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import numLib from "./numLib.vue";
import defaultAss from "./defaultAss.vue";
import netCard from "./netcardAssociation.vue";
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
  getTypeList,
  typeSelectList,
  getAgentAssociationList,
  addAgentAssociation,
  updateAgentAssociation,
  deleteAgentAssociation,
} from "@/api/configurationManagement.js";
import { getAgentSwitchList } from "@/api/agentManagement.js";
import { mapGetters } from "vuex";
export default {
  name: "agentAssociation",
  mixins: [resize],
  data() {
    return {
      activeName: "first",
      formInline: {agentId:""},
      tableData: [],
      operationAgent: [],
      typeList: [],
      referenceList: [],
      isEDIT: false,
      dialogFormVisible: false,
      addForm: {
        agentId: "",
        type: "",
        referenceId: "",
      },
      rules: {
        agentId: [
          { required: true, message: "请选择代理商", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择配置类型", trigger: "change" },
        ],
        referenceId: [
          { required: true, message: "请选择关联配置", trigger: "change" },
        ],
        // name: [{
        // 				required: true,
        // 				message: '不能为空',
        // 				trigger: 'blur'
        // 			},],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "100px",
      dialogTitle: "",
    };
  },
  created() {
    getAgentSwitchList({}).then((responseResult) => {
      //console.log(responseResult)
      if (responseResult.slide_msg_key == 1) {
        this.$alert(responseResult.slide_msg_message_key, "消息提示", {
          confirmButtonText: "确定",
        });
      }
      this.operationAgent = responseResult;
    });
    getTypeList({}).then((res) => {
      console.log(res);
      this.typeList = [];
      for (let i in res) {
        if (!window.g.name) {
          console.log(res[i]);
             if(window.g.config_white_name && window.g.config_white_name.huoma){
             if (res[i] == "广告位") {
            continue;
          } 
          }else{
             if (res[i] == "广告位"||res[i] == "活码") {
            continue;
          }
          }
          
        }
        this.typeList.push({ id: i, name: res[i] }); //
      }
    });
    this.getAgentAssociationList();
  },
  methods: {
     
    handleClick() {},
    typeChange(val) {
      typeSelectList({ type: val }).then((res) => {
        console.log(res);
        this.referenceList = [];
        for(let i of res){
           this.referenceList.push(i);
        }
        // for (let i in res) {
        //   this.referenceList.push({ id: i, name: res[i] }); //
        // }
      });
    },

    searchData() {
      this.page = 1;
      this.getAgentAssociationList();
    },
    onPageChange(page) {
      this.page = page;
      this.getAgentAssociationList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAgentAssociationList();
    },

    getAgentAssociationList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        agentId:this.formInline.agentId
      };

      getAgentAssociationList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    edit(row) {
      this.dialogTitle = "编辑";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.typeChange(row.type);
      this.addForm.id = row.id;
      this.isEDIT = true;
      this.addForm.agentId = row.agentId;
      this.addForm.type = row.type + "";
      this.addForm.referenceId = row.referenceId + "";
    },
    add() {
      this.referenceList = [];
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.isEDIT = false;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.id = "";
      this.addForm.agentId = "";
      this.addForm.type = "";
      this.addForm.referenceId = "";
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm.id) {
            updateAgentAssociation(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getAgentAssociationList();
              this.dialogFormVisible = false;
            });
          } else {
            addAgentAssociation(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getAgentAssociationList();
              this.dialogFormVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    delect(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteAgentAssociation({ id }).then((res) => {
          this.getAgentAssociationList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
  components: {
    netCard,
    numLib,
    defaultAss
  },
  computed: {
    ...mapGetters(["isCaptal","currentRole"]),
  },
};
</script>

<style>
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.agentAssociation .el-form-item__label {
  vertical-align: top;
}
</style>
