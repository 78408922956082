<template>
  <div class="partnerList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">合伙人列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       	<el-form-item>
					<el-select v-model="formInline.promoter" placeholder="上级合伙人" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
       
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="nickname" label="微信昵称"> </el-table-column>
        <el-table-column prop="mobilePhone" label="手机号">
             </el-table-column>
        <el-table-column prop="promoterNickname" label="上级昵称"> </el-table-column>
        <el-table-column prop="createTime" label="注册时间"> </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>
 
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  partnerList
} from "@/api/partner.js";

export default {
  name: "partnerList",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        promoter:""
      },
      tabledata: [],
      formLabelWidth: "200px",
     groupList:[],
    };
  },
  created() {
    this.getGroupList();
    this.getPartnerList();
  },
  methods: {
    getGroupList(){
let params = {
        pageSize: 100000,
        pageNo: 1,
      };
      
      partnerList(params).then((res) => {
        this.groupList = res.rows;
        console.log(this.groupList)
      });
    },
    getPartnerList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      if(!!this.formInline.promoter){
       Object.assign(params, this.formInline);
      }
     
      partnerList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPartnerList();
    },
    onPageChange(page) {
      this.page = page;
      this.getPartnerList();
    },
    searchData() {
      this.page = 1;
      this.getPartnerList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
 .partnerList  .el-date-editor.el-input{width:100%!important;}
.partnerList .el-form-item__label {
  vertical-align: top;
}
</style>

