<template>
  <div class="virtual-card-info fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">虚拟卡信息</span>
      <el-form
        inline
        ref="virtualInfoForm"
        v-model="virtualInfoFormData"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
        <el-form-item>
          <el-input
            v-model="virtualInfoFormData.virtualIccid"
            placeholder="虚拟号"
            class="iccid-width"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="virtualInfoFormData.shortIccid"
            placeholder="短号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="virtualInfoFormData.chipid"
            placeholder="chipid"
            class="iccid-width"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        ref="table"
        stripe
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="虚拟号" min-width="180" prop="virtualIccid">
        </el-table-column>
        <el-table-column label="短号" min-width="130" prop="shortIccid" />
        <el-table-column
          show-overflow-tooltip
          label="ESIM1"
          min-width="230"
          prop="iccidMark1"
        >
          <template slot-scope="scope">
            {{ getOperate(scope.row.iccidMark1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="ESIM2"
          min-width="230"
          prop="iccidMark2"
        >
          <template slot-scope="scope">
            {{ getOperate(scope.row.iccidMark2) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="ESIM3"
          min-width="230"
          prop="iccidMark3"
        >
          <template slot-scope="scope">
            {{ getOperate(scope.row.iccidMark3) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="USIM1"
          min-width="230"
          prop="iccidMark4"
        >
          <template slot-scope="scope">
            {{ getOperate(scope.row.iccidMark4) }}
          </template>
        </el-table-column>
        <el-table-column label="随机码" min-width="130" prop="chipid" />
        <el-table-column
          show-overflow-tooltip
          prop="activeIccid"
          label="在线卡号"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="changeReason"
          show-overflow-tooltip
          label="切换原因"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="changeTime"
          show-overflow-tooltip
          label="切换时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="mac"
          show-overflow-tooltip
          label="物理地址"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="imei"
          show-overflow-tooltip
          label="IMEI号"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ssid"
          show-overflow-tooltip
          label="SSID"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="创建时间"
          min-width="180"
          prop="createTime"
        >
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button
              class="themed-button"
              type="primary"
              @click="edit(scope.row)"
              v-if="currentRole == 0 && isCustomer"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="themed-button"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="编辑虚拟卡信息"
      :visible.sync="dialogCheckVisible"
      width="600"
      :destroy-on-close="true"
      v-if="dialogCheckVisible"
    >
      <el-form :model="checkForm" :rules="rules" ref="checkForm">
        <el-form-item
          label="虚拟卡号"
          :label-width="formLabelWidth"
          prop="virtualIccid"
        >
          <el-input
            v-model="checkForm.virtualIccid"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item
          label="短号"
          :label-width="formLabelWidth"
          prop="shortIccid"
        >
          <el-input
            v-model="checkForm.shortIccid"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="IMEI" :label-width="formLabelWidth" prop="imei">
          <el-input v-model="checkForm.imei" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="随机码"
          :label-width="formLabelWidth"
          prop="chipid"
        >
          <el-input v-model="checkForm.chipid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="ESIM1"
          :label-width="formLabelWidth"
          prop="iccidMark1"
        >
          <el-input
            v-model="checkForm.iccidMark1"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="ESIM2"
          :label-width="formLabelWidth"
          prop="iccidMark2"
        >
          <el-input
            v-model="checkForm.iccidMark2"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="ESIM3"
          :label-width="formLabelWidth"
          prop="iccidMark3"
        >
          <el-input
            v-model="checkForm.iccidMark3"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="themed-button"
          type="success"
          @click="sureChecked('checkForm')"
          >确 认</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogCheckVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import resize from "@/mixins/resize.js";
import Utils from "@/utils/utils.js";
import {
  virtualCardInfo,
  exportVirtualInfoList,
  deleteVirtualCard,
  updateVirtualCardInfo,
} from "@/api/virtualCard.js";
export default {
  name: "networkCutHistory",
  mixins: [resize],
  data() {
    return {
      isCustomer:window.g.name,
      virtualInfoFormData: {
        virtualIccid: "",
        chipid: "",
        shortIccid: "",
      },
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "130px",
      dialogCheckVisible: false,
      checkForm: {
        virtualIccid: "",
        imei: "",
        shortIccid: "",
        chipid: "",
        iccidMark1: "",
        iccidMark2: "",
        iccidMark3: "",
      },
      rules: {
        virtualIccid: [
          { required: true, message: "请填写虚拟卡号", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getVirtualCardInfoList();
  },
  computed: {
    ...mapGetters(["currentRole"]),
  },
  methods: {
    sureChecked(form) {
        this.$confirm('修改虚拟卡信息可能导致虚拟卡相关数据不准确，请谨慎操作, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.$refs[form].validate((valid) => {
        if (valid) {
          updateVirtualCardInfo(this.checkForm).then((res) => {
            this.dialogCheckVisible = false;
            this.getVirtualCardInfoList();
            this.$message({
              showClose: true,
              message: "修改成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
    
    },

    edit(data) {
      this.dialogCheckVisible = true;
      this.checkForm.virtualIccid = data.virtualIccid;
      this.checkForm.imei = data.imei;
      this.checkForm.shortIccid = data.shortIccid;
      this.checkForm.chipid = data.chipid;
      this.checkForm.iccidMark1 = data.iccidMark1;
      this.checkForm.iccidMark2 = data.iccidMark2;
      this.checkForm.iccidMark3 = data.iccidMark3;
      this.checkForm.id = data.id;
    },
    getOperate(iccid) {
      let str = iccid.substring(0, 6);
      let operator = "广电";
      if (
        str == "898600" ||
        str == "898602" ||
        str == "898604" ||
        str == "898607" || str == "898608"
      ) {
        operator = "移动";
      }
      if (str == "898601" || str == "898606" || str == "898609") {
        operator = "联通";
      }
      if (str == "898603" || str == "898611") {
        operator = "电信";
      }
      if (!!iccid && iccid.length >= 19) {
        return iccid + "(" + operator + ")";
      } else {
        return "";
      }
    },
    getVirtualCardInfoList() {
      let params = {
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      Object.assign(this.virtualInfoFormData, params);
      virtualCardInfo(this.virtualInfoFormData).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onPageChange(page) {
      this.page = page;
      this.getVirtualCardInfoList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getVirtualCardInfoList();
    },

    onSearch() {
      this.page = 1;
      this.getVirtualCardInfoList();
    },

    formatTime(time) {
      return Utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date(time));
    },

    handleDelete(id) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteVirtualCard({ id }).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getVirtualCardInfoList();
        });
      });
    },

    exportExcel() {
      exportVirtualInfoList(this.virtualInfoFormData).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style>
.virtual-card-info .el-form-item__label {
  vertical-align: top;
}
</style>