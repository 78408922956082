<template>

<div class="changeCard table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">换卡</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="form">
            <el-form-item label="老卡:">
                <el-input v-model="form.iccid" class="iccid-width"></el-input>
            </el-form-item>
             <el-form-item label="老卡短号:">
                <el-input v-model="form.shortIccid" class="iccid-width"></el-input>
            </el-form-item>
             <el-form-item label="新卡:">
                <el-input v-model="form.newIccid" class="iccid-width"></el-input>
            </el-form-item>
             <el-form-item label="新卡短号:">
                <el-input v-model="form.newShortIccid" class="iccid-width"></el-input>
            </el-form-item>
              <el-form-item label="姓名:">
                <el-input v-model="form.name" class="iccid-width"></el-input>
            </el-form-item>
             <el-form-item label="手机:">
                <el-input v-model="form.phone" class="iccid-width"></el-input>
            </el-form-item>
              <el-form-item label="快递单号:">
                <el-input v-model="form.expressNo" class="iccid-width"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">搜索查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRecord">导入卡信息</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addDeliver">导入快递单号</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" icon="el-icon-download"  class="themed-button" @click="exportChange">导出</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
            <el-table-column label="老卡" prop="iccid" width="180px"></el-table-column>
              <el-table-column label="新卡" prop="newIccid" show-overflow-tooltip></el-table-column>
             <el-table-column label="老卡短号" prop="shortIccid" width="180px"></el-table-column>
            <el-table-column label="新卡短号" prop="newShortIccid" show-overflow-tooltip></el-table-column>
             <el-table-column label="姓名" prop="name" show-overflow-tooltip></el-table-column>
            <el-table-column label="手机" prop="phone" show-overflow-tooltip></el-table-column>
            <el-table-column label="地址" prop="address" show-overflow-tooltip></el-table-column>
            <el-table-column label="状态" prop="status" show-overflow-tooltip>
               <template slot-scope="scope">
            <span v-if="scope.row.status==0">待处理</span>
            <span v-else-if="scope.row.status==1">通过</span>
            <span v-else-if="scope.row.status==2">拒绝</span>
          </template>
            </el-table-column>
             <el-table-column label="快递单号" prop="expressNo" show-overflow-tooltip></el-table-column>
             <el-table-column label="理由" prop="reason" show-overflow-tooltip></el-table-column>
            <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
             <el-table-column label="更新时间" prop="updateTime" show-overflow-tooltip></el-table-column>
              <el-table-column label="操作"  show-overflow-tooltip>
                <template slot-scope="scope">
                     <el-button type="danger"  class="themed-button" @click="deleteById(scope.row.id)">删除</el-button>
                     <el-button size="mini" class="themed-button" type="primary" v-show="scope.row.status==0" @click="operateCard(scope.row.id)">处理</el-button>
                </template>

              </el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>


    <el-dialog title="导入卡信息" :visible.sync="dialogBox">
        <el-form label-position="right" label-width="200px" :model="form">
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">导入excel模板</el-button>
            <el-button class="themed-button" type="info" @click="exportTemplateExcel('importIccidOrShort.xls')">导出excel模板
			</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBox = false">取 消</el-button>
        </div>
    </el-dialog>
     <el-dialog title="导入快递单号" :visible.sync="dialogBoxExpressNo">
        <el-form label-position="right" label-width="200px" >
            <el-form-item label="请选择excel文件:" required>
                <input class="file-input" type="file" ref='fileOne'/>
                <div class="mt5">
                        <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExpressNo">导入excel模板</el-button>
            <el-button class="themed-button" type="info" @click="exportTemplateExcel('importExpressNo.xls')">导出excel模板
			</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBoxExpressNo = false">取 消</el-button>
        </div>
    </el-dialog>
    	<el-dialog title="操作处理" :visible.sync="dialogEditVisible" width="600" :destroy-on-close='true'>
            <el-form :model="checkForm"  ref="checkForm">
			  <el-form-item label="状态"   :label-width="formLabelWidth" prop="status" >
					<el-select v-model="checkForm.status" @change="statusChange">
					<el-option label="通过" value=1></el-option>
                    <el-option label="拒绝" value=2></el-option>
					</el-select>
			    </el-form-item>

				<el-form-item label="新卡"    :label-width="formLabelWidth" prop="newIccid" v-show="statusOne">
                <el-input v-model="checkForm.newIccid" autocomplete="off"  style="width:208px"></el-input>
                </el-form-item>
                <el-form-item label="快递单号"   :label-width="formLabelWidth" prop="expressNo"  v-show="statusOne">
                <el-input v-model="checkForm.expressNo" autocomplete="off" style="width:208px"></el-input>
                </el-form-item>
                <el-form-item label="理由"   :label-width="formLabelWidth" prop="reason"  v-show="statusTwo">
                <el-input v-model="checkForm.reason" autocomplete="off" style="width:208px"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureChecked()">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogEditVisible = false">取 消</el-button>
            </div>
        </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import {changeCardList, importIccid,updateCard,deleteCard,exportExcelSync,importExpressNo} from '@/api/cardManagement/changeCard.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import SearchSelect from '@/components/SearchSelect'
import resize from '@/mixins/resize.js'
import Utils from '@/utils/utils.js'
import {exportTemplate,} from '@/api/statistics.js'
export default {
    name:'changeCard',
    mixins:[resize],
    data(){
        return {
            form:{
                iccid:'',
                newIccid:"",
                name:"",
                phone:"",
                expressNo:"",
                shortIccid:"",
                newShortIccid:"",
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogBox:false,
            dialogEditVisible:false,
            checkForm:{
                id:"",
                status:"",
                newIccid:"",
                expressNo:"",
                reason:""
            },
            statusOne:false,
            statusTwo:false,
            formLabelWidth:"120px",
            dialogBoxExpressNo :false,
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        addDeliver(){
         this.dialogBoxExpressNo  = true
         this.$refs['fileOne'].value = ""
        },
        exportChange(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
         exportExcelSync(params).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        searchTable(){
            this.getList()
        },
        addRecord(){
            this.dialogBox = true
            this.$refs['file'].value = ""
        },
        uploadExpressNo(){
         confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(1)
            })
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport(2)
            })
        },
        comfirmImport(type){
             let file 
            if(type==2){
              file = this.$refs['file'].files[0];
            }else if(type==1){
              file = this.$refs['fileOne'].files[0];
            }
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    if(type == 2 ){
                     importIccid(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBox =false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                    }else if(type == 1){
                        importExpressNo(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBoxExpressNo =false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                    }
                   
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
          deleteById(id){
            confirmBox(this,'确定要删除吗？','消息提示',()=>{
                deleteCard({id}).then((res)=>{
                   messageBox(this,'删除成功') 
                   this.getList()
                })
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this.getList()
        },
        getList(){
            let params = Object.assign(this.form,{
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            })
            changeCardList(params).then((res)=>{
                this.pageNo = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
                this.tableData = res.rows
            })
        },
        exportTemplateExcel(name) {
				exportTemplate({
					'name': name
				}).then(res => {
					const filename = name
					Utils.createDownload(res, filename)
				})
			},
        operateCard(id){
       this.dialogEditVisible = true
       this.checkForm.id = id
        },
        statusChange(val){
            if(val==1){
               this.statusOne = true
               this.statusTwo  = false
            }
            if(val==2){
               this.statusOne = false
               this.statusTwo  = true
            }
        },
        sureChecked(){
            if(this.checkForm.status==""){
              messageBox(this,'请选择状态')
                return
            }
            if(this.checkForm.status==1){
                if(this.checkForm.newIccid==""){
                 messageBox(this,'请填写新卡')
                return
                }
                if(this.checkForm.expressNo==""){
                  messageBox(this,'请填写订单')
                return
                }
             
            }
            if(this.checkForm.status==2){
            if(this.checkForm.reason==""){
                 messageBox(this,'请填写拒绝理由')
                return
                }
            }
           updateCard(this.checkForm).then(res=>{
            this.$message({
                    type: 'success',
                    message: '操作成功!'
                    });
                this.dialogEditVisible = false
                this.getList()
           }) 
        }
    },
    components:{
        SearchSelect
    }
}

</script>
<style>
.changeCard .el-form-item__label {
  vertical-align: top;
}

</style>