<template>
  <div class="customerList fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">客户列表</span>
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       	<el-form-item>
					<el-select v-model="formInline.promoter" placeholder="上级邀请人" clearable >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
       
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        :data="tabledata"
        stripe
        style="width: 100%"
        border
        ref="table"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="name" label="公司">
             </el-table-column>
         <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="promoterName" label="邀请人"> </el-table-column>
        <!-- promoter -->
         <el-table-column prop="promoter" label="操作"> 
            <template slot-scope="scope">
                        <el-button v-if="scope.row.promoter==''||scope.row.promoter==null" size="mini" class="themed-button" type="primary" @click="bindPartner(scope.row.id)">绑定邀请人</el-button>
            </template>
         </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      >
      </el-pagination>
    </div>

    	<el-dialog title="绑定邀请人" :visible.sync="dialogCheckVisible" width="360" v-if="dialogCheckVisible">
            <el-form :model="checkForm" :rules="rules" ref="checkForm">
		      <el-form-item  label="邀请人"  prop="promoter" label-width="150px">
					<el-select v-model="checkForm.promoter" placeholder="选择邀请人" clearable >
						<el-option v-for="(item) in groupList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureChecked('checkForm')">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogCheckVisible = false">取 消</el-button>
            </div>
        </el-dialog>
 
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import { mapGetters } from "vuex";
import {
  enterpriseList,partnerList,
  setPromoter
} from "@/api/partner.js";

export default {
  name: "customerList",
  mixins: [onresize],
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        promoter:""
      },
      tabledata: [],
      formLabelWidth: "200px",
      groupList:[],
      dialogCheckVisible:false,
      checkForm:{
        promoter:"",
        id:"",
        },
      rules:{
       promoter: [{ required: true, message: "请选择邀请人", trigger: "change" }],
        },
    };
  },
  created() {
    this.getGroupList();
    this.getEnterpriseList();
  },
  methods: {
    sureChecked(formName){
     this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
       
          this.dialogCheckVisible = false;
          setPromoter(this.checkForm).then((responseResult) => {
            console.log(responseResult,125)
            if (responseResult.system_result_key == '0'){
               this.$message({
               type: "success",
               message: "设置成功",
          });
            }
          
             this.getEnterpriseList()
             
          });
        } else {
          return false;
        }
      });
    },
    bindPartner(id){
      this.checkForm.id = id
      this.checkForm.promoter = ""
      this.dialogCheckVisible = true
      this.$nextTick(()=>{
       this.$refs['checkForm'].resetFields()
      })
      
     },
    getGroupList(){
    let params = {
        pageSize: 100000,
        pageNo: 1,
      };
      
      partnerList(params).then((res) => {
        this.groupList = res.rows;
        console.log(this.groupList)
      });
    },
      
    getEnterpriseList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
      };
      Object.assign(params, this.formInline);
      enterpriseList(params).then((res) => {
        /* 表格数据 */
        this.tabledata = res.rows;
        this.page = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getEnterpriseList();
    },
    onPageChange(page) {
      this.page = page;
      this.getEnterpriseList();
    },
    searchData() {
      this.page = 1;
      this.getEnterpriseList();
    },
  },
};
</script>

<style>
.right_agent {
  margin-right: 8px;
}
 .customerList  .el-date-editor.el-input{width:100%!important;}
.customerList .el-form-item__label {
  vertical-align: top;
}
</style>

