<template>
  <div class="numLib fadeInRight">
           <div class="title-box clearfix">
      <el-form
        :inline="true"
        :model="formInline"
        class="right themed-form search-form"
        style="height: auto; line-height: 40px"
      >
       <el-form-item>
                <el-input placeholder="请输入号码库或关联配置" v-model="matchNum" class="input-with-select" clearable>
                    <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
                </el-input>
      </el-form-item>

       <!-- <el-form-item>
          <el-input
            v-model="matchNum"
            placeholder="请输入号码库或关联配置"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchData"
            icon="el-icon-search"
            class="themed-button"
            >搜索</el-button
          >
        </el-form-item> -->
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            class="themed-button"
            @click="add"
            >新增</el-button
          >
        </el-form-item>
          <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="maxTableHeight"
      >
        <el-table-column prop="setName" label="号码库" min-width="180">
        </el-table-column>
        
        <el-table-column prop="typeStr" label="配置类型" min-width="120">
        </el-table-column>
      
          <el-table-column prop="referenceName" label="关联配置" min-width="120">
        </el-table-column>

        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
             
            <el-button
              type="primary"
              class="themed-button"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="themed-button"
              @click="delect(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>

      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
        <el-form :model="addForm" :rules="rules" ref="addForm">
          <el-form-item label="号码库:" prop="setId" :label-width="formLabelWidth">
         <el-select placeholder="号码库" v-model="addForm.setId" filterable  :disabled="isEDIT">
						 <el-option
                    v-for="item in libraryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
					</el-select>
          </el-form-item>
          <el-form-item label="配置类型:" prop="type" :label-width="formLabelWidth">
          <el-select placeholder="配置类型" v-model="addForm.type" filterable @change="typeChange" :disabled="isEDIT">
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
					</el-select>
          </el-form-item>
          
          <el-form-item label="关联配置:" prop="referenceId" :label-width="formLabelWidth">
             <el-select placeholder="关联配置" v-model="addForm.referenceId" filterable>
						<el-option v-for="item in referenceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
			 </el-select>
          
          </el-form-item>
           
         
         
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureAdd('addForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import store from "@/store";
import {
getTypeList,
typeSelectList,
numbelList,
getNumLibList,
delLibAssociation,
updateLibAssociation,
addLibAssociation,
setExportExcelAsync
} from "@/api/configurationManagement.js";
import { mapGetters } from "vuex";
export default {
  name: "numLib",
  mixins: [resize],
  data() {
  
    return {
      formInline: {},
      tableData: [],
      typeList:[],
      referenceList:[],
      isEDIT:false,
      dialogFormVisible: false,
      addForm: {
       setId:"",
       type:"",
       referenceId:"",
      },
      rules: {
         setId: [
          { required: true, message: "请选择号码库", trigger: "change" },
        ],
        type:[{required: true,message: '请选择配置类型',trigger: 'change'}],
        referenceId:[{required: true,message: '请选择关联配置',trigger: 'change'}],
       
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "100px",
      dialogTitle: "",
      libraryList:[],
      matchNum:"",
    };
  },
  created() {
   numbelList({ pageSize: 10000}).then((responseResult) => {
      console.log(responseResult)
     
      this.libraryList = responseResult.rows;
    });
   getTypeList({}).then(res=>{
      console.log(res)
      this.typeList = [];
    for(let i in res) {
      if (!window.g.name) {
          console.log(res[i]);
          if(window.g.config_white_name && window.g.config_white_name.huoma){
             if (res[i] == "广告位") {
            continue;
          } 
          }else{
             if (res[i] == "广告位"||res[i] == "活码") {
            continue;
          }
          }
         
        }
      this.typeList.push ({id:i,name:res[i]} )  //
    }
   })
   this.getNumLibList()
    
  },
  methods: {
      exportExcel(){
            setExportExcelAsync({ matchNum:this.matchNum}).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
    typeChange(val){
  typeSelectList({type:val}).then(res=>{
     console.log(res)
      this.referenceList = [];
    // for(let i in res) {
    //   this.referenceList.push ({id:i,name:res[i]} )  //
    // }
    for(let i of res){
           this.referenceList.push(i);
        }
  })
   },
   
    searchData() {
      this.page = 1;
      this.getNumLibList();
    },
    onPageChange(page) {
      this.page = page;
      this.getNumLibList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getNumLibList();
    },

    getNumLibList() {
      let params = {
        pageSize: this.pageSize,
        pageNo: this.page,
        matchNum:this.matchNum,
      };

      getNumLibList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    edit(row) {
      this.dialogTitle = "编辑";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.typeChange(row.type)
      this.addForm.id = row.id;
      this.isEDIT = true
       this.addForm.setId = row.setId;
      this.addForm.type = row.type+"";
      this.addForm.referenceId = row.referenceId+"";
    },
    add() {
      this.referenceList=[]
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.isEDIT = false
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      this.addForm.id = "";
      this.addForm.setId = "";
      this.addForm.type = "";
      this.addForm.referenceId = "";
    
    },
    sureAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm.id) {
            updateLibAssociation(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getNumLibList();
              this.dialogFormVisible = false;
            });
          } else {
            addLibAssociation(this.addForm).then((responseResult) => {
              if (responseResult.slide_msg_key == 1) {
                this.$message({
                  showClose: true,
                  message: responseResult.slide_msg_message_key,
                  type: "warning",
                });
              }
              this.getNumLibList();
              this.dialogFormVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    delect(id) {
      this.$confirm("是否确认删除?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delLibAssociation({ id }).then((res) => {
          this.getNumLibList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
  computed: {
    ...mapGetters(["isCaptal"]),
  },
};
</script>

<style>
/* .materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
} */
.netcardAssociation .el-form-item__label {
  vertical-align: top;
}
/* .search-form .el-input .el-input__inner{width:auto;} */
</style>
