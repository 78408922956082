/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

// /complaintsList.vue
export function changeCardList(params) {
    return request({
        url:'/card/changeApply/list.do?',
        method:'post',
        data:params,
    })
}
export function importIccid(params){
    return request({
        url: '/card/changeApply/import.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function updateCard(params) {
    return request({
        url:'/card/changeApply/update.do?',
        method:'post',
        data:params,
    })
}
export function deleteCard(params) {
    return request({
        url:'/card/changeApply/delete.do?',
        method:'post',
        data:params,
    })
}
export function importExpressNo(params) {//导入快递单号
    return request({
        url:'/card/changeApply/importExpressNo.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function exportExcelSync(params) {//导出
    return request({
        url:'/card/changeApply/exportExcelSync.do?',
        method:'post',
        data:params,
    })
}



